import ng from 'angular';
import acpCore from 'core';
import acpAuthEventService from './acp-auth-event-service';
import acpComponentFeeplan from 'apps/feeplan';
import acpComponentOverdraftDomain from 'apps/overdraft/components/overdraft-domain';

var component = ng
  .module('acp.component.auth-event-domain', [
    acpCore.name,
    acpComponentOverdraftDomain.name,
    acpComponentFeeplan.name
  ])
  .factory('acpAuthEventService', acpAuthEventService);

export default component;

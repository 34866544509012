// @ngInject
function acpAuthEventService(
  $q,
  $log,
  acpAnalytics,
  acpCoreDispatcher,
  acpFeeplanModel,
  acpOverdraftDomainModel,
  nsPermissions
) {
  var def = {};

  def.sendTrackingForAllUsers = function() {
    // When you come back from another scope, the other scope's permissions are cached
    nsPermissions.clear();
    nsPermissions
      .requestPermissions(['isPasswordAuthed'])
      .then(function(permissions) {
        if (!permissions.isPasswordAuthed) {
          return $q.reject();
        } else {
          return gatherUserData();
        }
      });
  };

  function gatherUserData() {
    return $q
      .all({
        gaOverdraft: acpOverdraftDomainModel.isOverdraftOptedIn(),
        gaFeePlan: acpFeeplanModel.getFeePlanName()
      })
      .then(
        function(gaData) {
          acpAnalytics.track(gaData);
        },
        function(error) {
          $log.error('GA send error', error);
        }
      );
  }

  def.listen = function() {
    // send upon any auth login changes
    acpCoreDispatcher.login.updated.onValue(def.sendTrackingForAllUsers);
  };

  return def;
}

export default acpAuthEventService;
